<template>
	<div>
		<div style="width: 94%;height: 170px;background: url(http://zgkzs.oss-cn-beijing.aliyuncs.com/public/64bd2265d0968.jpg) no-repeat;border-radius: 14px;position: absolute;top: 12px;left: 10px;z-index:3;display: flex;background-size: cover;">
			<div class="d-flex a-center position-absolute left-0 right-0" style="bottom: 90px;">
				<!-- 用户头像 -->
				<el-avatar :src="user.avatar ? user.avatar : 'https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png'" class="ml-3"></el-avatar>
				<div class="ml-3">
					<div style="color: #fff;font-size: 20px;" @click="openLogin">{{ token ? user.username : '去登录/注册' }}</div>
					<div style="background-color: #31302e;border-radius: 30px;width: 88px;height: 22px;" class="d-flex a-center j-center">
						<div style="color: #e3b574;font-size: 10px;">基础会员</div>
					</div>
				</div>
			</div>

			<div class="d-flex position-absolute " style="top: 204rpx;right: 0;left: 0;bottom: 16px;">
				<div style="width: 33%;text-align: center;" @click="gocz">
					<div style="color: #fff;font-weight: 800;font-size: 16px;">{{jf}}</div>
					<div style="display: block;color: #fff;font-size: 12px;">剩余次数</div>
				</div>
				<div style="width: 33%;text-align: center;">
					<div style="color: #fff;font-weight: 800;font-size: 16px;">{{twcs}}</div>
					<div style="display: block;color: #fff;font-size: 12px;">提问次数</div>
				</div>
				<div style="width: 33%;text-align: center;">
					<div style="color: #fff;font-weight: 800;font-size: 16px;">暂未开通</div>
					<div style="display: block;color: #fff;font-size: 12px;">会员有效期</div>
				</div>
			</div>
		</div>
		
		
		
		
		
		<div style="width: 94%;height: 124px;background: url(http://zgkzs.oss-cn-beijing.aliyuncs.com/public/64bd2265d0968.jpg) no-repeat;border-radius: 10px;position: absolute;top: 198px;left: 10px;z-index:3;background-size: cover;">
			<div class="m-2" style="color: #fff;font-size: 16px;font-weight: 800;">常用功能</div>
			<div class="d-flex">
				<div style="width: 25%;text-align: center;" class="py-2" @click="gocz">
					<img src="http://qfy0752.oss-cn-guangzhou.aliyuncs.com/public/64e3251db2759.png" style="width: 30px;height: 30px;margin-bottom: 10px;">
					<div style="display: block;color: #fff;font-size: 14px;">会员充值</div>
				</div>
				<div style="width: 25%;text-align: center;" class="py-2" @click="">
					<img src="http://qfy0752.oss-cn-guangzhou.aliyuncs.com/public/64e3251db2599.png" style="width: 30px;height: 30px;margin-bottom: 10px;">
					<div style="display: block;color: #fff;font-size: 14px;">邀请好友</div>
				</div>
				<div style="width: 25%;text-align: center;" class="py-2">
					<img src="http://qfy0752.oss-cn-guangzhou.aliyuncs.com/public/64e3251db97b5.png" style="width: 30px;height: 30px;margin-bottom: 10px;">
					<div style="display: block;color: #fff;font-size: 14px;">帮助中心</div>
				</div>
				<div style="width: 25%;text-align: center;" class="py-2" @click="">
					<img src="http://qfy0752.oss-cn-guangzhou.aliyuncs.com/public/64e3251db876f.png" style="width: 30px;height: 30px;margin-bottom: 10px;">
					<div style="display: block;color: #fff;font-size: 14px;">专属客服</div>
				</div>
			</div>
		</div>
	
		<!-- <div style="width: 94%;height: 400rpx;background-color: #ffffff;border-radius: 20upx;position: absolute;top: 1042rpx;left: 23rpx;z-index:3;">
			<div class="d-flex a-center m-3" v-for="(i,indexs) in date" :key="indexs" @click="gotos(i.url)">
				<div class="d-flex a-center" style="width: 10%;">
					<img :src="i.src" style="width: 40rpx;height: 40rpx;">
				</div>
				<div class="d-flex a-center" style="width: 86%;">
					<div style="color: #616161;">{{i.div}}</div>
				</div>
				<div class="d-flex a-center" style="width: 4%;">
					<div class="iconfont icon-you font" style="color: #96938e;"></div>
				</div>
			</div>
			</div>
		</div> -->
	</div>
</template>

<script>
	import { mapState } from 'vuex'; // 引入 全局 公共数据
	export default {
		data() {
			return {
				jf:0,
				yq:0,
				zp:0,
				zyd:0,
				zxz:0,
				twcs:0
			}
		},
		computed: {
			...mapState({
				user: state => state.user.user,
				token: state => state.user.token
			}),
		},
		created() {
			this.myuser()
			document.title = '问问GPT'
		},
		methods: {
			myuser() {
				this.axios.get('/admin/myuser/'+this.user.id, {
					token: true
				}).then(res=>{
					let data = res.data.data
					this.yq = data.useruser.length
					this.jf = data.jf
				})
				this.axios.get('/admin/twcs/'+this.user.id, {
					token: true
				}).then(res=>{
					let data = res.data.data
					this.twcs = data.length
				})
				this.axios.get('/admin/mylist/0/'+this.user.id, {
					token: true
				}).then(res=>{
					let data = res.data.data
					this.zp = data.length
					let xx = 0
					let zz = 0
					for(let i of data){
						xx += i.gzrs
						zz += i.sale_count
					}
					this.zyd = xx
					this.zxz = zz
				})
			
			},
			openLogin() {
				this.$router.push({
					name: 'login'
				})
			},
			gocz(){
				this.$router.push({
					name: 'index_cz'
				})
			}
		}
	}
</script>

<style>
	.a-center{
		align-items: center;
	}
	.j-center{
		justify-content: center;
	}
	.position-absolute{ position: absolute; }
</style>
